import React, { useState, useEffect } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const useAuthentication = firebase => {
  const [state, setState] = useState({ authUser: null, firebaseStarted: false });
  const [authenticationLoaded, setAuthenticationLoaded] = useState();
  useEffect(() => {
    setState(state => ({
      ...state,
      authUser: JSON.parse(localStorage.getItem('authUser')),
    }));
    let listener = () => {}
    if (firebase && !state.firebaseStarted) {
      setState(state => ({
          ...state,
          firebaseStarted: true,
        })
      );
      setAuthenticationLoaded(true)
      listener = firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem(
            'authUser',
            JSON.stringify(authUser),
          );
          setState(state => ({ ...state, authUser }));
        },
        () => {
          localStorage.removeItem('authUser');
          setState((state) => ({ ...state, authUser: null }));
        },
      );
      return () => listener();
    }
  }, [state.firebaseStarted, firebase])

  return {state, authenticationLoaded};
}

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const {state, authenticationLoaded} = useAuthentication(props.firebase);
    return (
      <>
      {authenticationLoaded && <AuthUserContext.Provider value={state.authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>}
      {!authenticationLoaded && <div>loading...</div>}
      </>
    );
  }
  return withFirebase(WithAuthentication);
}

export default withAuthentication;
