import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';

import {
  withAuthorization,
  withAuthentication,
} from '../../components/Session';
import { withFirebase } from '../../components/Firebase';

import Head from '../../components/Form/layout/Head';
import Body from '../../components/Form/layout/Body';
import Footer from '../../components/Form/layout/Footer';
import SignOutButton from '../../components/Session/SignOutButton';

const translations = {
  pageTitle: 'Home',
  page: {
    title: 'Home',
  },
}

const condition = authUser => !!authUser;

const HomeBase = (props) => {
  return (
    <Fragment>
        <Head>
          <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
        </Head>
        <Body>
          <SignOutButton />
        </Body>
        <Footer>
        </Footer>
    </Fragment>
  )
};

const Home = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(HomeBase);


export default () => (
  <React.Fragment>
    <Home />
  </React.Fragment>
);
