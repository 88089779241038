import React, { Fragment, useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import AuthUserContext from '../../../components/Session/context';
import useDBList from '../../../hooks/useDBList';
import {
  withAuthorization,
  withAuthentication,
} from '../../../components/Session';
import { withFirebase } from '../../../components/Firebase';

import Head from '../../../components/Form/layout/Head';
import Body from '../../../components/Form/layout/Body';
import Footer from '../../../components/Form/layout/Footer';
import Table from '../../../components/Table';

const translations = {
  pageTitle: 'Fundas',
  page: {
    title: 'Fundas',
  },
}

const columns = [
  { title: 'título', field: 'data.title'},
  { title: 'nombre', field: 'data.name'},
  { title: 'marca', field: 'data.brand'},
  { title: 'size', field: 'data.sleeveSizeName'},
  { title: 'pvp', field: 'data.listPriceWithVat', type: 'currency'},
  { title: 'precio shop', field: 'data.shopPrice', type: 'currency'},
  { title: 'unidades', field: 'data.units', type: 'numeric'},
  { title: 'en stock', field: 'data.inStock', type: 'boolean'},
  { title: 'codigo prov', field: 'data.sellerCode'},
]

const condition = authUser => (!!authUser && authUser.isAdmin === true) && (authUser.emailVerified === true);

const CardsSleevesBase = (props) => {
  const [items, setItems] = useState([])
  const { firebase } = props;
  const user = useContext(AuthUserContext);

  const { loaded, updateElement } = useDBList(firebase, '/products/cardSleeves/items', items, setItems);
  const onRowUpdate = (tableRef) => async (newData, oldData) => {
    const dataUpdate = [...items];
    const index = oldData.tableData.id;
    const { id, data } = newData;
    dataUpdate[index] = newData;
    await updateElement(id, data);
    return;
  };

  const editable = (tableRef) => ({
    onRowUpdate: onRowUpdate(tableRef),
    onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
  })

  return (
    <>
    {user && <Fragment>
        <Head>
          <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
        </Head>
        <Body>
          {loaded && <Table title={translations.pageTitle} columns={columns} data={items} editable={editable} />}
        </Body>
        <Footer>
        </Footer>
    </Fragment>}
    {!user && <div>loading...</div>}
    </>
  )
};

const CardsSleeves = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(CardsSleevesBase);


export default () => (
  <React.Fragment>
    <CardsSleeves />
  </React.Fragment>
);
