const GAME_FOR_SALE_PATH = '/products/gameForSale'

export default {
  LANDING: '/',
  HOME: '/home',
  GAMES_FOR_SALE: '/products/gamesForSale',
  GAME_FOR_SALE_PATH,
  GAME_FOR_SALE: `${GAME_FOR_SALE_PATH}/:gameId`,
  CARD_SLEEVES: '/products/cardSleeves',
  GAMEUNITS: '/games/units',
  GAMEUNITS_BA: '/games/units/ba',
  GAMEUNITS_BERLIN: '/games/units/berlin',
  GAMEUNITS_LOCATED: '/games/units/:city/location/:location',
  GAMEUNITS_BA_LOCATED_UNKNOWN: '/games/units/ba/location/unknown',
  GAMEUNIT: '/games/unit/:gameId',
  LOCATIONS: '/games/units/locations',
  LOGIN: '/login',
}

