import React, { Fragment, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Link, useParams } from '@reach/router'

import useDBList from '../../hooks/useDBList';
import {
  withAuthorization,
  withAuthentication,
} from '../../components/Session';
import { withFirebase } from '../../components/Firebase';

import Head from '../../components/Form/layout/Head';
import Body from '../../components/Form/layout/Body';
import Footer from '../../components/Form/layout/Footer';
import Table, { timestamp } from '../../components/Table';

const translations = {
  pageTitle: 'Unidades',
  page: {
    title: 'Unidades',
  },
}

const columns = [
  { title: 'id', field: 'data.unitId'},
  { title: 'tipo', field: 'data.type'},
  { title: 'título', render: (dataRow) => <Link to={`/games/unit/${dataRow.id}`}>{dataRow.data.name}</Link>},
  { title: 'gameId', field: 'data.gameId'},
  { title: 'ciudad', field: 'data.city'},
  { title: 'ubicación', field: 'data.location'},
  { title: 'creación', field: 'data.createdAt', render: rowData => timestamp(rowData.data.createdAt)},
  { title: 'actualizado', field: 'data.lastModified', render: rowData => timestamp(rowData.data.lastModified)},
  { title: 'bid', field: 'data.bggId'},
  { title: 'cid', field: 'data.bggCollectionId'},
]

const condition = authUser => !!authUser;

const GameUnitsBase = (props) => {
  const [games, setGames] = useState([])
  const setGamesCB = useCallback((list) => setGames(list), [])
  const params = useParams();
  const { city, location } = params;
  const { firebase } = props;
  let filters = (city) ? [['city', '==', city]] : undefined
  let sort = [['createdAt', 'desc']]
  if (location && location === 'unknown') {
    filters.push(['location', '==', 'unknown'])
  } else if (location && location !== 'unknown') {
    filters.push(['locationId', '==', location])
  } else {
    // no location
  }
  console.log('sort', sort)
  console.log('location', location)
  console.log('filters', filters)
  const { loaded } = useDBList(firebase, '/gameUnits', games, setGamesCB, null ,sort, filters);
  const onRowUpdate = async (newData, oldData) => {
    // const dataUpdate = [...games];
    // const index = oldData.tableData.id;
    // console.log('newData', newData);
    // const { id, data } = newData;
    console.log('oldData', oldData);
    // // console.log('oldData.tableData.id', oldData.tableData.id);
    // dataUpdate[index] = newData;
    // await updateElement(id, data);

    return Promise.resolve();
  };

  const editable = (tableRef) => ({
    onRowUpdate: onRowUpdate,
    onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
  })

  return (
    <Fragment>
        <Head>
          <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
        </Head>
        <Body>
          {loaded && <Table columns={columns} data={games} editable={editable} />}
        </Body>
        <Footer>
        </Footer>
    </Fragment>
  )
};

const GameUnits = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(GameUnitsBase);


export default () => (
  <React.Fragment>
    <GameUnits />
  </React.Fragment>
);
