const callRestFunction = async (url, body) => {
  const data = body;
  const request = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  };
  const response = await fetch(url, request);
  console.log('response', response);
  return response.json();
}

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor(app) {

    app.default.initializeApp(config);

    /* Helper */

    this.fieldValue = app.default.firestore.FieldValue;
    this.emailAuthProvider = app.default.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.default.auth();
   
    this.functions = app.default.functions();
    this.db = app.default.firestore();
    // The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
    // for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED"
    // to disable clean-up.
    this.db.settings({
      cacheSizeBytes: this.db.CACHE_SIZE_UNLIMITED
    });

    this.db.enablePersistence()
      .catch((err) => {
        if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
    });
    // Subsequent queries will use persistence, if it was enabled successfully

  
    this.storage = app.default.storage();
    this.analytics = app.default.analytics();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithEmailLink = (email, link) =>
    this.auth.signInWithEmailLink(email, link);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

  doSendSignInLinkToEmail = (email) =>
    this.auth.sendSignInLinkToEmail(email, {
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT + '/app/account',
      handleCodeInApp: true,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

      // *** User API ***
  isAdmin = user => user.roles['ADMIN'] === 'ADMIN';

  isStudent = user => !(this.isTutor(user) || this.isAdmin(user));

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  onAuthUserListener = (next, fallback, caller = 'unknown') =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser && authUser.isAnonymous === false) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // merge auth and db user
            const combined = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              isAdmin: (dbUser.roles && dbUser.roles.ADMIN && dbUser.roles.ADMIN === 'ADMIN'),
              ...dbUser,
            };

            next(combined);
          });
      } else {
        fallback();
      }
    });

}

let firebase;

function getFirebase(app, auth, database, functions, storage, analytics) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, functions, storage, analytics);
  }

  return firebase;
}

export default getFirebase;
