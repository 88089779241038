import React from 'react';
import { navigate } from '@reach/router';
import Button from '@material-ui/core/Button';

import ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';

const translations = {
  ui: {
    buttons: {
      signOut: {
        label: 'Sign Out',
      },
    },
  },
};

const SignOutButton = ({ firebase }) => {
  const signOut = async () => {
    if (firebase) {
      await firebase.doSignOut();
      navigate(ROUTES.LANDING);
    } else {
      return () => Promise.resolve();
    }
  }
  return (
    <Button
      variant="contained"
      type="button"
      onClick={signOut}
    >
      {translations.ui.buttons.signOut.label}
    </Button>
  )
};

export default withFirebase(SignOutButton);
