import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
const theme = responsiveFontSizes(createMuiTheme({
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
  },
  palette: {
    primary: {
      main: '#ff5722',
      contrastText: '#fafafa',
    },
    secondary: {
      main: '#00B8D4',
      contrastText: '#fafafa',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fafafa',
    },
  },
}));

export default theme;
