import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import validate from 'validate.js';
import EmailRounded from '@material-ui/icons/EmailRounded';

const FIELD = 'email';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 12,
  },
}));

export const validator = (value, inputs) => {
  const result = validate({ email: value }, { email: { email: true, presence: true } });
  const errorMessage = (result && result.email) ? result.email.join(' ') : null;
  return { valid: (result === undefined), error: errorMessage }
};

const Email = ({ handleInputChange, handleCheckboxChange, handleFocus, handleBlur, inputs, ...props }) => {
  const classes = useStyles();
  const iconColor = (inputs.fields[FIELD].error) ? 'error' : 'disabled';
  return (
    <TextField
      id={FIELD}
      type="email"
      placeholder="Email"
      onChange={handleInputChange(FIELD)}
      onBlur={handleBlur(FIELD)}
      onFocus={handleFocus(FIELD)}
      value={inputs.fields[FIELD].value}
      error={!!inputs.fields[FIELD].error}
      helperText={inputs.fields[FIELD].error}
      required={inputs.fields[FIELD].required}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box className={classes.button} >
              <EmailRounded color={iconColor} />
            </Box>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
};

export default Email;