import { useCallback, useEffect, useState } from 'react';


const useDBEntry = (firebase, refString, id, element, setElement) => {
  const [loaded, setLoaded] = useState(false);
  const query = useCallback(async (refString, id) => {
    let docRef = firebase
      .db
      .collection(refString)
      .doc(id)

    const doc = await docRef.get()
    console.log('data', refString, id, doc.data())
    setElement(doc.data());
    setLoaded(true);
    return doc.data()
  }, [firebase, setElement])
  useEffect(() => {
    if (firebase) {
      console.log('query')
      query(refString, id)
    } else {
      return null
    }
  }, [firebase, query, refString, id]);
  const updateElement = useCallback(async (id, data) => {
    return firebase
      .db
      .collection(refString)
      .doc(id)
      .set({
        ...data,
        updated_at: firebase.fieldValue.serverTimestamp(),
      }, { merge: true })
      .then(() => {
        return query(refString, id)
      })
  }, [firebase, refString, query]);
    
  return {
    loaded, 
    updateElement,
    query,
  };
};

export default useDBEntry;
