import React, { useState, useLayoutEffect } from 'react';
import { navigate } from '@reach/router';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import ROUTES from '../../constants/routes';

const useAuthorization = (firebase, condition) => {
  const [state] = useState({ firebaseStarted: false });
  const [authLoaded, setAuthLoaded] = useState(false);
  useLayoutEffect(() => {
    let listener = () => {}
    if (firebase && !state.firebaseStarted) {
      listener = firebase.onAuthUserListener(
        user => {
          setAuthLoaded(true);
          if (!condition(user)) {
            navigate(ROUTES.LOGIN);
          }
        },
        () => navigate(ROUTES.LOGIN),
        'authorization',
      );
      return listener;
    }
  }, [condition, firebase, state.firebaseStarted, authLoaded]);
  return { authLoaded };
}

const withAuthorization = condition => Component => {
  const WithAuthor = props => {
    const {authLoaded } = useAuthorization(props.firebase, condition);
    return (
      <React.Fragment>
      {authLoaded &&
        <AuthUserContext.Consumer>
          {authUser => {
           return (condition(authUser) ? <Component {...props} /> : <div>&nbsp;</div>)
          }
          }
        </AuthUserContext.Consumer>}
        {!authLoaded && <div>Loading</div>}
      </React.Fragment>
    );
  }
  return withFirebase(WithAuthor);
}

export default withAuthorization;
