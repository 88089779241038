import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

import SignInForm from './SignInForm';
// import { PasswordForgetLink } from '../passwordForget/PasswordForgetForm';

import Head from '../../components/Form/layout/Head';
import Body from '../../components/Form/layout/Body';
import Footer from '../../components/Form/layout/Footer';

const translations = {
  pageTitle: 'Log In',
  page: {
    title: 'Log In',
  },
}

const Login = () => {
  return (
    <Fragment>
      <Head>
        <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
      </Head>
      <Body>
        <SignInForm />
      </Body>
      <Footer>
      footer
      </Footer>
    </Fragment>
  )
};

export default Login;
