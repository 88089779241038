import React, { useLayoutEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Router } from "@reach/router"


import getFirebase, { FirebaseContext } from './components/Firebase';
import withAuthentication from './components/Session/withAuthentication';
import AuthenticatedTopBar from './components/AuthenticatedTopBar';
import theme from './ui/theme';
import ROUTES from './constants/routes';
import './App.css';
// Views
import Login from './views/Login';
import Home from './views/Home';
import GamesForSale from './views/Products/GamesForSale';
import GameForSale from './views/Products/GameForSale';
import CardSleeves from './views/Products/CardSleeves';
import GameUnits from './views/GameUnits';
import Locations from './views/GameUnits/Locations';
import Game from './views/Game';

const isBrowser = () => (typeof window !== 'undefined');
const useFirebase = () => {
  const [ firebase, setFirebase] = useState(null);
  useLayoutEffect(() => {
    if (isBrowser()) {

      const loadFirebase = async () => {
        const app = import('firebase/app');
        const auth = import('firebase/auth');
        const firestore = import('firebase/firestore');
        const functions = import('firebase/functions');
        const storage = import('firebase/storage');
        const analytics = import('firebase/analytics');
        
        Promise.all([app, auth, firestore, functions, storage, analytics]).then(values => {
          const firebase = getFirebase(values[0]);  
          setFirebase(firebase);
        });
      };
      if (firebase === null) {
        loadFirebase();
      }
    }
  }, [firebase]);
  return firebase;
}

const Layout = (props) => {
  const firebase = useFirebase();
  return (
    <FirebaseContext.Provider value={firebase}>
      <App {...props} />
    </FirebaseContext.Provider>
  );
}

const App = withAuthentication((props) => {
  console.log('withAuthentication App', props)
  return (
    <div className="App">
      <CssBaseline /> 
      <ThemeProvider theme={theme}>
        <Container>
          <AuthenticatedTopBar />
          <Router>
            <Login path={ROUTES.LOGIN} />
            <Home path={ROUTES.HOME} />
            <GamesForSale path={ROUTES.GAMES_FOR_SALE} />
            <GameForSale path={ROUTES.GAME_FOR_SALE} />
            <CardSleeves path={ROUTES.CARD_SLEEVES} />
            <GameUnits path={ROUTES.GAMEUNITS_LOCATED} />
            <GameUnits path={ROUTES.GAMEUNITS_BA} />
            <GameUnits path={ROUTES.GAMEUNITS_BERLIN} />
            <GameUnits path={ROUTES.GAMEUNITS} />
            <Locations path={ROUTES.LOCATIONS} />
            <Game path={ROUTES.GAMEUNIT} />
          </Router>
        </Container>
      </ThemeProvider> 
    </div>
  );
});

export default Layout;
