import React, { Fragment, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Link, useParams } from '@reach/router'

import useDBList from '../../../hooks/useDBList';
import {
  withAuthorization,
  withAuthentication,
} from '../../../components/Session';
import { withFirebase } from '../../../components/Firebase';

import Head from '../../../components/Form/layout/Head';
import Body from '../../../components/Form/layout/Body';
import Footer from '../../../components/Form/layout/Footer';
import Table, { timestamp } from '../../../components/Table';

const translations = {
  pageTitle: 'Ubicaciones',
  page: {
    title: 'Ubicaciones',
  },
}

const columns = [
  { title: 'id', field: 'data.id'},
  { title: 'name', field: 'data.name'},
]

const condition = authUser => !!authUser;

const LocationsBase = (props) => {
  const [locations, setLocations] = useState([])
  const setLocationsCB = useCallback((list) => setLocations(list), [])
  const params = useParams();
  const { firebase } = props;
  const { loaded, addElement, deleteElement } = useDBList(firebase, '/locations', locations, setLocationsCB);
  const onRowUpdate = async (newData, oldData) => {
    // const dataUpdate = [...games];
    // const index = oldData.tableData.id;
    // console.log('newData', newData);
    // const { id, data } = newData;
    console.log('oldData', oldData);
    // // console.log('oldData.tableData.id', oldData.tableData.id);
    // dataUpdate[index] = newData;
    // await updateElement(id, data);

    return Promise.resolve();
  };

  const onRowAdd = async (newData) => {
    const { data } = newData
    await addElement(data)
    setLocations([...locations, newData]);
  }
  const onRowDelete = async (oldData) => {
    const { id } = oldData
    await deleteElement(id)
    const dataDelete = [...locations];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    setLocations([...dataDelete]);
    return Promise.resolve()
  }
  const editable = (tableRef) => ({
    onRowUpdate: onRowUpdate,
    // onRowDelete,
    onRowAdd, 
    onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
  })

  return (
    <Fragment>
        <Head>
          <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
        </Head>
        <Body>
          {loaded && <Table columns={columns} data={locations} editable={editable} />}
        </Body>
        <Footer>
        </Footer>
    </Fragment>
  )
};

const Locations = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(LocationsBase);


export default () => (
  <React.Fragment>
    <Locations />
  </React.Fragment>
);
