import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { navigate } from "@reach/router"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import routes from '../../constants/routes'
import NestedMenuItem from '../NestedMenuItem'

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuBar(props) {
  const classes = useStyles();
  const [anchorElements, setAnchorElements] = React.useState({});
  const [menuPosition, setMenuPosition] = React.useState(null);

  const setAnchor = (menu, value) => {
    setAnchorElements((prev) => {
      return {
        ...prev,
        [menu]: value,
      }
    });
  }
  const handleClick = menu => event => {
    console.log('event.currentTarget', event.currentTarget)
    if (!anchorElements[menu]) {
      setAnchor(menu, event.currentTarget)
    }
  };

  const navigateTo = (event, page) => {
    console.log('nav', page)
    event.preventDefault();
    setMenuPosition(null);
    setAnchorElements({})
    navigate(page);
  }

  const handleClose = useCallback((menu) => () => {
    console.log('away', menu)
    if (!menu) {
      setAnchorElements({})
    } else {
      setAnchor(menu, null);
    }
  }, [anchorElements]);

  const handleItemClick = (event) => {
    setMenuPosition(null);
  };


  const openRoot = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  console.log('anchorElements', anchorElements)
  return (
    <ClickAwayListener onClickAway={handleClose('root')}>
    <React.Fragment>
        <IconButton edge="start" className={classes.menuButton} color="primary" aria-label="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={openRoot}>
          <MenuIcon />
        </IconButton>
        <Menu
          open={!!menuPosition}
          onClose={() => setMenuPosition(null)}
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}
          TransitionComponent={Fade}
        >
          <MenuItem selected={(props.menu === 'index')} onClick={(event) => navigateTo(event, "/home")}>Home</MenuItem>
          <MenuItem selected={(props.menu === 'products/gamesForSale')} onClick={(event) => navigateTo(event, "/products/gamesForSale")}>Juegos en Venta</MenuItem>
          <MenuItem selected={(props.menu === 'products/cardSleeves')} onClick={(event) => navigateTo(event, '/products/cardSleeves')}>Fundas</MenuItem>
          <NestedMenuItem
            label="Ludoteca"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem selected={(props.menu === routes.GAMEUNITS)} onClick={(event) => navigateTo(event, routes.GAMEUNITS)}>Ludoteca Todes</MenuItem>
            <NestedMenuItem
              label="BA"
              parentMenuOpen={!!menuPosition}
              onClick={handleItemClick}
            >
              <MenuItem selected={(props.menu === routes.GAMEUNITS_BA_LOCATED_UNKNOWN)} onClick={(event) => navigateTo(event, routes.GAMEUNITS_BA_LOCATED_UNKNOWN)}>Ubicación sin especificar</MenuItem>
              <MenuItem selected={(props.menu === routes.GAMEUNITS)} onClick={(event) => navigateTo(event, routes.GAMEUNITS_BA)}>Todos los juegos</MenuItem>

            </NestedMenuItem>

            <MenuItem selected={(props.menu === routes.GAMEUNITS)} onClick={(event) => navigateTo(event, routes.GAMEUNITS_BERLIN)}>Ludoteca Berlin</MenuItem>
            <MenuItem selected={(props.menu === routes.LOCATIONS)} onClick={(event) => navigateTo(event, routes.LOCATIONS)}>Ubicaciones</MenuItem>

          </NestedMenuItem>
        </Menu>
        </React.Fragment>
        </ClickAwayListener>
    );
}