import React, { Fragment, useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Link } from '@reach/router'
import AuthUserContext from '../../../components/Session/context';
import useDBList from '../../../hooks/useDBList';
import {
  withAuthorization,
  withAuthentication,
} from '../../../components/Session';
import { withFirebase } from '../../../components/Firebase';

import Head from '../../../components/Form/layout/Head';
import Body from '../../../components/Form/layout/Body';
import Footer from '../../../components/Form/layout/Footer';
import Table from '../../../components/Table';
import ROUTES from '../../../constants/routes'

const translations = {
  pageTitle: 'Juegos en venta',
  page: {
    title: 'Juegos en venta',
  },
}

const columns = [
  { title: 'título', field: 'data.title', type: 'string', render: (rowData) => <Link to={`${ROUTES.GAME_FOR_SALE_PATH}/${rowData.id}`}>{rowData.data.title}</Link>},
  { title: 'pvp', field: 'data.listPriceWithVat', type: 'currency'},
  { title: 'precio shop', field: 'data.shopPrice', type: 'currency'},
  { title: 'unidades', field: 'data.units', type: 'numeric'},
  { title: 'en stock', field: 'data.inStock', type: 'boolean'},
  { title: 'proveedor', field: 'data.currentSellerName', type: 'string', searchable: false},
  { title: 'preventa', field: 'data.preorderAvailable', type: 'boolean'},
  { title: 'preventa inicia', field: 'data.preorderStarts', type: 'date'},
  { title: 'preventa finaliza', field: 'data.preorderEnds', type: 'date'},
  { title: 'usd', field: 'data.usdReference', type: 'currency'},
]

const condition = authUser => (!!authUser && authUser.isAdmin === true) && (authUser.emailVerified === true);

const GamesForSaleBase = (props) => {
  const [games, setGames] = useState([])
  const { firebase } = props;
  const user = useContext(AuthUserContext);

  const { loaded, updateElement } = useDBList(firebase, '/products/gameForSale/items', games, setGames, null, ['title']);
  const onRowUpdate = (tableRef) => async (newData, oldData) => {
    const dataUpdate = [...games];
    const index = oldData.tableData.id;
    const { id, data } = newData;
    dataUpdate[index] = newData;
    await updateElement(id, data);

    return Promise.resolve();
  };

  const editable = (tableRef) => ({
    onRowUpdate: onRowUpdate(tableRef),
  })

  return (
    <>
    {user && <Fragment>
        <Head>
          <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
        </Head>
        <Body>
          {loaded && <Table columns={columns} data={games} editable={editable} />}
        </Body>
        <Footer>
        </Footer>
    </Fragment>}
    {!user && <div>loading...</div>}
    </>
  )
};

const GamesForSale = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(GamesForSaleBase);


export default () => (
  <React.Fragment>
    <GamesForSale />
  </React.Fragment>
);
