import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ROUTES from '../../constants/routes';
import useForm, { DEFAULT_FIELD } from '../../hooks/useForm';
import Email, { validator as emailValidator } from '../../components/Form/inputs/Email';
import Password, { validator as passwordValidator } from '../../components/Form/inputs/Password';
import ButtonWithLoading
  from '../../components/Form/inputs/ButtonWithLoading';
import { navigate } from "@reach/router"
import { FirebaseContext } from '../../components/Firebase';

import { Typography } from '@material-ui/core';

const translations = {
  ui: {
    buttons: {
      emailSignIn: {
        label: 'Sign In',
        newLink: 'An error ocurred. Request a new email link',
      },
      googleSignIn: 'Sign In with Google',
      facebookSignIn: 'Sign In with Facebook',
    },
    errors: {
      ERROR_EMAIL_SIGNIN_AUTH_PASSWORD: 'The combination of email and password does not match any account',
    },
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: 3,
  },
}));

const ERROR_EMAIL_SIGNIN_AUTH_PASSWORD = `auth/wrong-password`;

const validators = {
  email: emailValidator,
  password: passwordValidator,
};

const submitEmailLinkSignIn = ({ event, inputs, props, setInputs }) => {
  const email = inputs.fields.email.value;
  const { firebase } = props;

  return firebase.doSignInWithEmailLink(email, window.location.href)
    .then((authCredential) => {

      navigate(ROUTES.HOME);
      return ({
        result: {},
      });
    })
    .catch(error => {
      console.log('error', error);
      if (error.code === 'auth/invalid-action-code')
        return ({
          errors: error,
          result: {},
        });
    });

};

const submitEmailSignIn = ({ event, inputs, props, setInputs }) => {
  const { email, password } = inputs.fields;
  const { firebase } = props;
  event.persist();

  return firebase
    .doSignInWithEmailAndPassword(email.value, password.value)
    .then((authCredential) => {

      setInputs(nextInputs => ({
        ...nextInputs,
        fields: {
          ...nextInputs.fields,
          email: DEFAULT_FIELD,
          password: DEFAULT_FIELD,
        },
      }));
      navigate(ROUTES.HOME);
      return ({
        result: {},
      });
    })
    .catch(error => {
      console.log('error', error);
      return ({
        errors: error,
        result: {},
      });
    });

};

const SignInError = ({ errors, className }) => {
  const classes = useStyles();
  if (!errors) return null;
  if (errors[0] && errors[0].code === ERROR_EMAIL_SIGNIN_AUTH_PASSWORD) {
    return (
      <Box className={classes.error}>
        <Typography><strong>Error: </strong>{translations.ui.errors.ERROR_EMAIL_SIGNIN_AUTH_PASSWORD}
        </Typography>
      </Box>
    );
  } else if (errors[0]) {
    return (
      <Box className={classes.error}>
        <Typography><strong>Unexpected
          error: </strong>{errors[0].code}</Typography>
      </Box>
    );
  }
  return null;
};

const SignInForm = props => {
  const firebase = useContext(FirebaseContext)
  const classes = useStyles();
  const {
    handleSubmit,
    inputsProps,
    loading,
    success,
  } = useForm({...props, firebase }, submitEmailSignIn, {
    email: DEFAULT_FIELD,
    password: DEFAULT_FIELD,
  }, validators);

  return (
    <form className={classes.container}>
      <Email
        {...inputsProps}
        className={classes.fields}
      />
      <Password
        {...inputsProps}
        className={classes.fields}
      />
      <Box className={classes.formButtons}>
        <ButtonWithLoading
          color="primary"
          disabled={!inputsProps.inputs.dirty}
          onClick={event => handleSubmit(event, inputsProps.inputs)}
          loading={loading} success={success}
          label={translations.ui.buttons.emailSignIn.label}/>
      </Box>
      <SignInError errors={inputsProps.inputs.errors}/>
    </form>
  );
};

export const SignInEmailLinkBase = props => {
  const classes = useStyles();
  const {
    handleSubmit,
    inputsProps,
    loading,
    success,
  } = useForm(props, submitEmailLinkSignIn, {
    email: DEFAULT_FIELD,
  }, validators);

  const requestNewEmail = async () => {
    const email = inputsProps.inputs.fields.email.value;
    props.firebase.doSendSignInLinkToEmail(email);
  };

  return (
    <form className={classes.container}>
      <Email
        {...inputsProps}
        className={classes.fields}
      />
      {!inputsProps.inputs.errors &&
      <Box className={classes.formButtons}>
        <ButtonWithLoading
          onClick={event => handleSubmit(event, inputsProps.inputs)}
          loading={loading} success={success}
          label={translations.ui.buttons.emailSignIn.label}/>
      </Box>
      }
      {inputsProps.inputs.errors && <Button
        onClick={requestNewEmail}>{translations.ui.buttons.emailSignIn.newLink}</Button>
      }
    </form>
  );
};

export default SignInForm;
