import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react';
import * as contentful from 'contentful'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormLabel from '@material-ui/core/FormLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import { compose } from 'recompose';
import AuthUserContext from '../../components/Session/context';
import { useParams } from "@reach/router"
import useDBEntry from '../../hooks/useDBEntry';
import useDBList from '../../hooks/useDBList';

import {
  withAuthorization,
  withAuthentication,
} from '../../components/Session';
import { withFirebase } from '../../components/Firebase';

import Head from '../../components/Form/layout/Head';
import Body from '../../components/Form/layout/Body';
import Footer from '../../components/Form/layout/Footer';

const LOCATION_CONTENT_TYPE_ID = 'location'

const condition = authUser => (!!authUser && authUser.isAdmin === true) && (authUser.emailVerified === true);
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 180,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
}));

const LANGUAGES = [
  'spanish',
  'english',
  'german',
  'other',
]

const GameBase = (props) => {
  const [game, setGame] = useState()
  const [updating, setUpdating] = useState(true)
  const [saving, setSaving] = useState(false)
  const [locations, setLocations] = useState()
  const params = useParams();
  const { firebase} = props;
  const { gameId:id } = params;
  const user = useContext(AuthUserContext);
  const classes = useStyles();
  const { loaded, updateElement } = useDBEntry(firebase, '/gameUnits', id, game, setGame);
  
  const changeField = (field) => (ev) => {
    ev.persist()
    setGame((prev) => {
      const updated = {
        ...prev,
        [field]: ev.target.value,
      }
      return updated
    })
  }

  const changeBooleanObject = (field) => (ev) => {
    ev.persist()
    setGame((prev) => {
      const prevData = prev || {}
      const prevLang = prev.languages || {}
      const updatedLang = {
        ...prevLang,
        [field]: ev.target.checked,

      }
      const updated = {
        ...prevData,
        languages: updatedLang
      }
      return updated
    })
  }

  const updateGame = useCallback(async (ev, cb = () => {}) => {
    setGame(async (prev) => {
      const updatedGame = {
        ...prev,
        location: prev.locationId || 'unknown'
      }
      console.log('saving', id, updatedGame)
      setSaving(true)
      await updateElement(id, updatedGame)
      setSaving(false)
      cb()
      return updatedGame;  
    })
  }, [updateElement, id, game])

  // console.log('game', game)

  useEffect(() => {
    const getLocations = async () => {
      const client = contentful.createClient({
        // This is the space ID. A space is like a project folder in Contentful terms
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
      });
      const result = await client.getEntries({ 'content_type': `${LOCATION_CONTENT_TYPE_ID}`});
      const data = result.items.map(loc => loc.fields)
      setLocations(data)
    }
    if (!locations) {
      getLocations()
    }

  }, [locations])

  useEffect(() => {
    if (game) {
      if (!game.imageUrl && updating) {
        const client = contentful.createClient({
          // This is the space ID. A space is like a project folder in Contentful terms
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
          accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
        });
        const getGameEntry = async (gameId) => {
          try {
            const gameEntry = await client.getEntry(gameId)
            console.log('getEntry', gameId)
            setGame(async (prev) => {
              const updated = {
                ...prev,
                imageUrl: gameEntry.fields.image.fields.file.url,
              }
              setSaving(true)
              await updateElement(id, updated)
              setSaving(false)
              setUpdating(false)
              return updated
            })
  
          } catch(error) {
            console.log('error', error)
          }
          // updateGame('', () => {
          //   setUpdating(false)
          // })
    
        }
        getGameEntry(game.gameId)
      } else {
        setUpdating(false)
      }
    }
  }, [game, updateGame, updating])

  return (
    <>
    {user && <Fragment>
          {loaded && !updating && locations &&
            <>
            <Head>
              <Typography variant="h3" gutterBottom align="center">{game.name}</Typography>
            </Head>
            <Body>
              <Grid container>
                <Grid item xl={3} md={3} lg={3} sm={12} xs={12}>
                  <img src={game.imageUrl} alt={game.title} width={200} />
                </Grid>
                <Grid item xl={9} md={9} lg={9} sm={12} xs={12}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid item xs={12}>
                      <TextField label="unit id" value={game.unitId} InputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="type" value={game.type} InputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="game id" value={game.gameId} InputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="bgg collection id" value={game.bggCollectionId} InputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="bgg id" value={game.bggId} InputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="city" value={game.city} InputProps={{ readOnly: true }} />
                    </Grid>
                    {game.createdAt && <Grid item xs={12}>
                      <TextField label="created at" value={new Date(game.createdAt.seconds * 1000)} InputProps={{ readOnly: true }} />
                    </Grid>}
                    <Grid item xs={12} className={classes.root}>
                      <FormControl fullWidth>
                        <InputLabel id="location-select-label">Location</InputLabel>
                        <Select
                          labelId="location-select-label"
                          id="location-select"
                          value={game.locationId || ''} 
                          autoWidth
                          onChange={changeField('locationId')}           
                        >
                          {locations.map((loc) => {
                            return (
                              <MenuItem key={loc.locationId} value={loc.locationId}>{loc.name}</MenuItem>
                            )                            
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.root}>
                      <FormLabel component="legend">Game rules</FormLabel>
                      <FormGroup>
                        {LANGUAGES.map((lang) => {
                          return (
                            <FormControlLabel key={lang} control={<Checkbox checked={game.languages && game.languages[lang] || false} onChange={changeBooleanObject(lang)} />} label={lang} />
                          )
                        })}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12}>
                      <Button color="primary" variant="contained" disabled={saving} onClick={updateGame}>Save</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                <pre>{JSON.stringify(game, null,2)}</pre>

                </Grid>
              </Grid>
              
            </Body>
            <Footer>
            </Footer>
            </>
          }
    </Fragment>}
    {!user && <div>loading...</div>}
    </>
  )
};

const Game = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(GameBase);


export default () => (
  <React.Fragment>
    <Game />
  </React.Fragment>
);
