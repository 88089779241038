import React, { Fragment, useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { compose } from 'recompose';
import AuthUserContext from '../../../components/Session/context';
import { useParams } from "@reach/router"
import useDBEntry from '../../../hooks/useDBEntry';
import useDBList from '../../../hooks/useDBList';

import {
  withAuthorization,
  withAuthentication,
} from '../../../components/Session';
import { withFirebase } from '../../../components/Firebase';

import Head from '../../../components/Form/layout/Head';
import Body from '../../../components/Form/layout/Body';
import Footer from '../../../components/Form/layout/Footer';

const condition = authUser => (!!authUser && authUser.isAdmin === true) && (authUser.emailVerified === true);

const IPrices = () => {
  return null;
  // return (
  //   <div style={{height: 400, width: '100%'}} dangerouslySetInnerHTML={{ __html: "<iframe width=\"100%\" height=\"100%\" src='https://www.cazagangas.com.ar/juegos/buscar' />"}} />
  // );
}


const GameForSaleBase = (props) => {
  const [game, setGame] = useState({})
  const [prices, setPrices] = useState([])
  const [saving, setSaving] = useState(false)
  const params = useParams();
  // console.log('params', params)
  const { firebase} = props;
  const { gameId:id } = params;
  const user = useContext(AuthUserContext);

  const { loaded, updateElement } = useDBEntry(firebase, '/products/gameForSale/items', id, game, setGame);
  const { loaded:pricesLoaded } = useDBList(firebase, `/products/gameForSale/history/${id}/prices`, prices, setPrices, null, ['created_at']);

  const changeField = (field) => (ev) => {
    ev.persist()
    setGame((prev) => {
      const updated = {
        ...prev,
        [field]: parseInt(ev.target.value, 10),
      }
      return updated
    })
  }

  const changeBoolean = (field) => (ev) => {
    ev.persist()
    setGame((prev) => {
      const updated = {
        ...prev,
        [field]: ev.target.checked,
      }
      return updated
    })
  }

  const updateGame = async (ev) => {
    setSaving(true)
    // console.log('saving', id, game)
    await updateElement(id, game)
    setSaving(false)
  }
  // console.log('game', game)
  return (
    <>
    {user && <Fragment>
          {loaded && pricesLoaded &&
            <>
            <Head>
              <Typography variant="h3" gutterBottom align="center">{game.title}</Typography>
          </Head>
            <Body>
              <Grid container>
                <Grid item xl={3} md={3} lg={3} sm={12} xs={12}>
                  <img src={game.imageUrl} alt={game.title} width={200} />
                </Grid>
                <Grid item xl={9} md={9} lg={9} sm={12} xs={12}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid item xs={12}>
                      <TextField label="shop price" value={(game.shopPrice || 0).toString()} onChange={changeField('shopPrice')} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="list price" value={(game.listPriceWithVat || 0).toString()} onChange={changeField('listPriceWithVat')}/>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={game.inStock} onChange={changeBoolean('inStock')} />} label="In Stock" />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={game.preorderAvailable} onChange={changeBoolean('preorderAvailable')}  />} label="Preorder Available" />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12}>
                      <Button color="primary" variant="contained" disabled={saving} onClick={updateGame}>Save</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Grid container style={{margin: '1rem'}}>
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h5">Historial de precios</Typography>
                    </Grid>
                  </Grid>
                  {prices.map((price) => {
                    return (
                      <Grid container direction="row" key={price.id}>
                        <Grid item xs={4}>{price.id}</Grid>
                        <Grid item xs={4}>{price.data.shopPrice}</Grid>
                        <Grid item xs={4}>{price.data.newShopPrice}</Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{width: '100%'}}>
                  <IPrices />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                {/* <pre>{JSON.stringify(game, null,2)}</pre> */}

                </Grid>
              </Grid>
              
            </Body>
            <Footer>
            </Footer>
            </>
          }
    </Fragment>}
    {!user && <div>loading...</div>}
    </>
  )
};

const GameForSale = compose(
  withFirebase,
  withAuthentication,
  withAuthorization(condition),
)(GameForSaleBase);


export default () => (
  <React.Fragment>
    <GameForSale />
  </React.Fragment>
);
