import { useEffect, useState, useCallback } from 'react';

const whereBuilder = (query, filters = []) => {
  return filters.reduce((acc, filter) => {
    return acc.where(filter[0], filter[1], filter[2])
  }, query)
}

const queryBuilder = (firebase, refString, orderBy=[], filters=[]) => {
  let query = firebase
        .db
        .collection(refString)
  query = whereBuilder(query, filters)

  return orderBy.reduce((accum, order) => {
    if (Array.isArray(order)) {
      return accum.orderBy(order[0], order[1])
    }
    // return accum.orderBy(order).limit(10)
    return accum.orderBy(order).limit(400)
  }, query)
}

const useDBList = (firebase, refString, elements, setElements, associationsUpdaters= [], orderBy=[], filters=[]) => {
  const [loaded, setLoaded] = useState(false);
  const query = () => {
    let unsubscribe = () => {}
    if (firebase) {
      return queryBuilder(firebase, refString, orderBy, filters)
        .onSnapshot((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((doc) => {
            list.push({id: doc.id, data: doc.data()});
          })
          setElements(list);
          setLoaded(true);
        });
    } 
    return unsubscribe;
  }
  useEffect(() => {
    if (firebase) {
      query()
    } else {
      return
    }
  }, []);
  const updateElement = async (id, data) => {
    return firebase
      .db
      .collection(refString)
      .doc(id)
      .set({
        ...data,
        updated_at: firebase.fieldValue.serverTimestamp(),
      }, { merge: true })
      .then(() => {
        return query()
      })
    };
  const addElement = async (entry) => {
    const entryWithTimestamp = {
      ...entry,
      created_at: firebase.fieldValue.serverTimestamp(),
    }
    return await 
      firebase
        .db
        .collection(refString)
        .add(entryWithTimestamp);
  }
  const deleteElement = useCallback(async (id) => {
    return await firebase
        .db
        .collection(refString)
        .doc(id)
        .delete()
  }, [elements]);
  return {
    addElement,
    deleteElement,
    loaded, 
    updateElement,
    query,
  };
};

export default useDBList;
